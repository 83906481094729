<template>
  <div>
    <h1>Invoices</h1>
  </div>
</template>

<script>
export default {
  name: "invoices"
};
</script>
